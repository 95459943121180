.table-period_container_1it6I {
  font-size: 14px;
  line-height: 1;
}
.table-period_search_2AY_E {
  line-height: 1;
  font-size: 14px;
  padding: 10px 0;
}
.table-period_search_2AY_E .twitter-typeahead {
  width: 100%;
}
.table-period_search_2AY_E .tt-hint,
.table-period_search_2AY_E .tt-input {
  height: 32px;
  padding: 0 8px;
  border: 1px solid hsl(94.43181818, 1%, 85%);
  border-radius: 4px;
  background: hsl(94.43181818, 1%, 97%);
  width: 100%;
  box-sizing: border-box;
}
.table-period_search_2AY_E .tt-hint:focus,
.table-period_search_2AY_E .tt-input:focus {
  border-color: #4bb000;
}
@media (min-width: 769px) {
  .table-period_search_2AY_E {
    display: none;
  }
}
.table-period_headerContainer_2w6w5 {
  position: sticky;
  top: 48px;
  padding: 16px 0 14px;
  z-index: 99;
  background-color: #fff;
  margin-bottom: 16px;
}
.table-period_stuckHeaderContainer_1Derm {
  box-shadow: 0 2px 3px 0 hsla(94.43181818, 1%, 78%, 0.5);
}
.table-period_header_28AQB {
  display: flex;
  align-items: center;
}
.table-period_pageTitleAndShareContainer_1oUNP {
  flex: 1;
  display: flex;
}
.table-period_userHeader_2-HoE {
  display: flex;
}
.table-period_userPageTitle_1Lq3O {
  font-size: 20px;
  color: hsl(94.43181818, 1%, 25%);
  white-space: nowrap;
  flex: 1;
}
.table-period_fullTableLink_2SJAb {
  color: #4bb000;
  font-weight: bold;
  text-decoration: none;
  line-height: 22px;
}
.table-period_pageTitle_3gjxH {
  font-size: 20px;
  display: inline-block;
  color: hsl(94.43181818, 1%, 25%);
  text-decoration: none;
  padding: 8px;
  margin: -8px;
  border-radius: 4px;
  white-space: nowrap;
}
.table-period_pageTitle_3gjxH svg {
  margin-left: 5px;
}
.table-period_pageTitle_3gjxH:hover {
  background-color: hsl(94.43181818, 1%, 97%);
}
.table-period_nav_1Hea8 {
  margin-top: 8px;
  margin-left: -8px;
  width: auto;
}
.table-period_navRow_BCnFb {
  display: flex;
}
.table-period_navYear_H8sBa {
  width: 80px;
  align-self: center;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}
.table-period_navPeriod_284m7 {
  width: 40px;
  color: hsl(94.43181818, 1%, 25%);
  text-decoration: none;
  text-align: center;
  padding: 20px 10px;
}
.table-period_navPeriod_284m7:hover {
  background-color: hsl(94.43181818, 1%, 97%);
}
.table-period_navPeriodNormal_tD_nj {
}
.table-period_navPeriodActive_2vzNA {
  font-weight: bold;
}
.table-period_navPeriodHidden_31r77 {
  visibility: hidden;
}
.table-period_settings_2HsSm {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.table-period_settingsItem_2vK0T {
  margin-left: 15px;
}
.table-period_shareButtonContainer_1BP9i {
  position: relative;
  margin-left: 15px;
  align-self: center;
}
.table-period_shareButton_3uNG4 {
  white-space: nowrap;
  font-weight: bold;
  padding: 0 10px;
  color: #06c;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  border-radius: 2px;
}
.table-period_shareButton_3uNG4 svg {
  margin-right: 5px;
}
.table-period_shareButtonPopover_60YkX {
  position: absolute;
  top: 22px;
  left: 0;
  z-index: 100;
  color: rgba(255, 255, 255, 0.95);
  background: #555;
  box-shadow: rgba(0, 0, 0, 0.24) 0 0 8px 0;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.2;
  padding: 8px;
  white-space: nowrap;
  margin-top: 5px;
  margin-left: 10px;
  animation: 0.15s ease-in-out tableShareButtonPopoverShow;
}
.table-period_shareButtonPopover_60YkX:after {
  bottom: 100%;
  left: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: #555;
  border-width: 5px;
}
@keyframes tableShareButtonPopoverShow {
  from {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }
  to {
    opacity: 1;
    transform: translateY(0, 0, 0);
  }
}
.table-period_recommendationBetaNotice_1HJT9 {
  color: hsl(94.43181818, 1%, 25%);
  background-color: hsl(94.43181818, 1%, 97%);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 1.5;
}
.table-period_recommendationBetaNotice_1HJT9 strong {
  padding-right: 15px;
}
@media (max-width: 480px) {
  .table-period_recommendationBetaNotice_1HJT9 strong {
    display: block;
    padding-bottom: 5px;
  }
}
.table-period_itemsEmpty_27xwx {
  text-align: center;
  padding: 50px 0;
}
.table-period_item_DqvbN {
  margin-bottom: 20px;
}
.table-period_poster_17bqE {
  float: left;
  width: 144px;
  height: 192px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}
.table-period_posterImage_r_vky {
  display: block;
  width: 100%;
  height: auto;
}
.table-period_posterOverlay_1rbyI {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 8px;
  padding: 5px 8px;
  font-size: 13px;
  color: #fff;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 999px;
}
.table-period_itemContent_3vfbR {
  margin-left: 144px;
  padding-left: 16px;
  padding-bottom: 16px;
}
.table-period_title_2A9Er {
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
}
.table-period_duration_1y3Sv {
  font-size: 12px;
  border-radius: 2px;
  background-color: #06c;
  color: #fff;
  padding: 2px 5px;
  margin-left: 5px;
  white-space: nowrap;
}
.table-period_info_NfKO9 {
  padding-top: 8px;
  font-size: 13px;
  color: hsl(94.43181818, 1%, 25%);
}
.table-period_info_NfKO9 .studio,
.table-period_info_NfKO9 .source {
  white-space: nowrap;
}
.table-period_actions_2-Gz3 {
  padding: 16px 0;
}
.table-period_favoriteButton_8kvNt {
  display: block;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  border: 1px solid #4bb000;
  border-radius: 3px;
}
.table-period_favoriteButton_8kvNt svg {
  margin-right: 5px;
}
.table-period_favoriteButtonNormal_2YeYd {
  color: #4bb000;
}
.table-period_favoriteButtonActive_3xqug {
  background: #4bb000;
  color: #fff;
}
.table-period_favoriteButtonSubtext_2x65l {
  margin-left: 4px;
  font-size: 13px;
  font-weight: normal;
}
.table-period_schedules_Bkvgd {
  padding-bottom: 8px;
}
.table-period_schedule_cX6RL {
  font-size: 12px;
  color: hsl(94.43181818, 1%, 25%);
  padding-bottom: 2px;
}
.table-period_schedule_cX6RL .date {
  font-weight: bold;
  margin-right: 2px;
}
.table-period_schedule_cX6RL .broadcasts {
  color: hsl(94.43181818, 1%, 50%);
  font-size: 12px;
  white-space: nowrap;
}
.item-schedule-jp:before {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC30lEQVRYw+1WzWsTURCfTdZ80LUqtCIFc1AKTbAgWsFDqyfBgsf+Dx78AwTBiwfvHkQE/wSPPXixqKWgxQhaqRDBiGjwYDXkcz+SXd+8ZjazL2/T6CGXZmDy8mZn3+83897MW4CJTOSwiwGGYX7a3fUSiQT4vj8WUMLKFwpHTDHPBEEAuVwOcJSsDCNUdMYxZNyz038Ueg9H+k9zBCI7X79cLuPfDBIw0cnzPOnAgVHRRiQ4ICfFSXAiBI4jJ4Lv9rJtJvCXP4yLMrJv/2hTyRMpyYAT0C2gKkpjpwS11zvQLv/AlyF79jRMX16EqXPzA0Rwrq4dS0BlrC7m2w5UHj+F6ua7iL31+RvsPduC4ysXYO7mGhjplDaIWALdbje+TNgC5ftPoPG+1CufqGCMvze2wd2rwpl7t7RngwthJlQCupSj/Hn5FmrbHyFwXam+60WU7OiDvgetR5hhBmzbloZkMimVqoBeqqy/ED5t3kCUDPQjRd/UUj6SblTEIeABAtQgeO1ztb9WRISd2K3iiUZfKmHKAjUfGiME0ECRogMnQxp0uhB4oxFAX55uXAsBCZz1gX4GdLXPCWTmZsH58n0kAuirnnp17YEt0NU815nry1B9/mYkAuira988SG0VxHUzSWB1GY5eLMhtGKbTlxalr66P6DIwQEDXPknyj+7C7OqKOIy90nP2db8MPfls4eEdbQBqJjqdTn8LaKLeZqokp7Iw/+A2nFy7Br/WX0HjQ0m2Yuv8AszcuALHRCcc1nz4mYgQcEUDQcX6T6VSQ289nJ+4uiT1oOuYP6Oy7OLNK8ARLySATajZbIbg1Ih0qYvborgrml9GPjYlsd2OwGu1Wv0z4DgO1Ot1acTvAt6M1G8BHXjcnN4NA+qdNwyWCJjUfovFIqTTabAsC7LZrLTpTvH/iky/AG+321Cr1cA0zfCb0BLjKaEWjFcaQn/Kj1JsXpSNMQqWgQ0TOfTyF6yD/mWmnla2AAAAAElFTkSuQmCC) left center no-repeat;
  background-size: 16px 16px;
  padding-left: 22px;
}
.item-schedule-kr {
  padding-top: 7px;
}
.item-schedule-kr:before {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAEyklEQVRYw+1XTUhjVxT+El+i+VHTsRm1Y2aKYovRMgWxjlZhSmUsVYaRtpRKR9BVN4KIFNqFC8FVdyLqprNqUSTQOrQMKuIfbmpFq3V0I0qt+C9Wo/lPes/R++Y9k1UXbuohN/fdk3vv+e53z88LcCM38n8XAwwGZeXly5DRaEQ0Gr0Wo9JWgdttUsQ4JRaL4e7du6CeURkMaiNJSkp6hVijl71cR718JolEIjq9dv/19XV6TDGKL4XQhEIhbuFwmBst3t/fR29vL9bW1nQba58T6eh5Y2MDfX192Nzc5L3kvtLOJdsKAeDBVYTUBgcHebHH48Hw8DB8Pp/u5Lq7vNQFAgGMjo7yWjI0MjKiY03akdetA6CV8fFxeL1e3sRqtWJ5eRkmk0k3J+oLcNOKoihYXV2F2WxmMJmZmZiamopjSwJQtAAkyu3tbYyNjSErK4t1u7u7aG5uZgA09v6+jOPRaQT/3uX5pmwnHFXvI/XBfQZQX1+PiYkJHB4eYmVlBQcHB+xjOTk5iQFIZ9Geory8HPPz8zyxsrKSwZAcDL7A3g/PRfgIwIpyQfvmNk5/+wOvf/YxnF8+htPphMvl4vUEuqysjBnRirQZB4BOSLTV1NQgLy8PMzMzqK6u5t9OZv/E7jMPDGbBBEXG5bUpIqxiAuj+jz8h+d4dpFUWo7S0FEtLSyguLobb7eZ9tXbiAPj9fjXkqFGs5ubmMojz83NExXjh53Gk+s7ELCsMnEQE/WRYMSNkMOKWz48dz69QiguYZroK6oPBIDNJdqThhAyQUUIqe237JxTDzM4ZnoSjCAaCF8YRxfN0F8bSsxEW/vyueQ9PN7Zh8J7DkGrTRZVMPrLXASCFdECaoAUjW3IsgqmUDNisTlT4jxAVuhep2fCkucQGZCiGaWsmXKEo3NEIYppQpb3IoDRO+8U5YaIMpwVgTzHh9m0Hvt+6h19S7yBiNOBAsQLBEBunD4JhbOW+CUN6Gi3Whd3VvVXWrwLQNor9rq4ulcLPPywUsR3CXzEztkQLiLsNCKOBYEToRX8eQMVHJRwdtKanpwcLCwtxh9E6pM4HJLqdnR32fkpGJycnGBoaQl1dHR49eAtPPy3Fs/4Z4a0Cu+kiDBEW60MRfPHJe6h5WMT+MTk5ienpaSwuLqKiokINZbJBjMQxoEV5dnaGgYEB2Gw2Dqf+/n5sbW3xb980foDvvq3DfXeOyJBmWCxmvPP2G+j8+jE6vnoEo5izt7fH7FVVVXFEUSo/Pj7WMUB1QWVADmSeptCrra3F3NwcJ5CMjAx0dHSgu7ubx08eFnI7PvVxKngtzaKup706Ozs5dAsKCjihka6wsFB1PDlPZYDilBrlfekwjY2NnBtmZ2dxdHTEyYSKkfYUt9JtyHDYdDrK//n5+Tg9PeVMSIdoaGhQoypClVcYJ3sqADJEtFMvSyVNbm9vZwpbW1vR0tICh8ORMEK0OrvdznWjra2N2WpqanpVvKgGiOsOCDvEkHoFhJoQWywWrgPUy0xI7wM01pZgrdFELyY0LikpQVFRkVoD+FCX/kaH1QGgUxJVycnJfAIySLpEdf+/ivR8ukaKLOWykNFR7KKnUme/5vdRL0U8v5TSu5lk4xqFwsB/87/gRv4FWvK1+SyQvjEAAAAASUVORK5CYII=) left center no-repeat;
  background-size: 16px 16px;
  padding-left: 22px;
}
.table-period_credits_38H0w {
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 1.5;
  color: hsl(94.43181818, 1%, 50%);
  letter-spacing: -0.05em;
}
.table-period_credit_w2Vyb {
  color: hsl(94.43181818, 1%, 25%);
  word-break: keep-all;
}
.table-period_creditType_2bAJa {
  font-weight: bold;
  padding-right: 5px;
}
.table-period_creditRelated_1zB2J {
  color: hsl(94.43181818, 1%, 50%);
}
.table-period_links_3yPfj {
  border-top: 1px solid hsl(94.43181818, 1%, 85%);
  padding-top: 8px;
}
.table-period_links_3yPfj .link {
  font-size: 12px;
  color: hsl(94.43181818, 1%, 25%);
  text-decoration: none;
  margin-right: 10px;
}
.table-period_links_3yPfj .link:hover {
  text-decoration: underline;
}
.table-period_links_3yPfj .link:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .table-period_header_28AQB {
    flex-direction: column;
  }
  .table-period_pageTitleAndShareContainer_1oUNP {
    text-align: center;
    padding-bottom: 8px;
  }
  .table-period_shareButtonPopover_60YkX {
    left: auto;
    right: 0;
  }
  .table-period_shareButtonPopover_60YkX:after {
    left: auto;
    right: 20px;
  }
  .table-period_settings_2HsSm {
    margin: 0 auto;
    padding-top: 5px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .table-period_settingsItem_2vK0T {
    padding-bottom: 5px;
  }
  .table-period_settingsItem_2vK0T:first-child {
    margin-left: 0;
  }
  .table-period_nav_1Hea8 {
    left: 50%;
    margin-left: -160px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 480px) {
  .table-period_settings_2HsSm {
    flex-direction: column;
  }
  .table-period_settingsItem_2vK0T {
    margin-left: 0;
  }
  .table-period_settingsItem_2vK0T:last-child {
    padding-bottom: 0;
  }
  .table-period_item_DqvbN {
    margin-bottom: 32px;
  }
  .table-period_poster_17bqE {
    width: 120px;
    height: 160px;
  }
  .table-period_itemContent_3vfbR {
    margin-left: 120px;
    padding-bottom: 0;
  }
}
