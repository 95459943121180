@media (min-width: 481px) {
  .WorkViews_container_3l3QS {
    background-color: hsl(94.43181818, 1%, 97%);
    overflow: hidden;
  }
}
.WorkViews_sectionTitle_1SDCA {
  line-height: 1;
  font-size: 1.2em;
  font-weight: normal;
  margin-bottom: 0;
  padding-bottom: 16px;
}
.WorkViews_sectionTitle_1SDCA svg {
  margin-right: 4px;
}
.WorkViews_main_2oiOu {
  padding: 16px 0;
  margin-bottom: 32px;
}
@media (min-width: 481px) {
  .WorkViews_main_2oiOu {
    background-color: #fff;
    box-shadow: 0 0 20px hsl(94.43181818, 1%, 78%);
    border-radius: 2px;
    margin-top: 16px;
    margin-left: -16px;
    padding: 16px;
  }
}
.WorkViews_header_CbI-N {
  overflow: hidden;
}
@media (max-width: 480px) {
  .WorkViews_header_CbI-N {
    display: flex;
    align-items: center;
  }
}
.WorkViews_poster_MncJb {
  float: left;
  width: 144px;
}
@media (max-width: 480px) {
  .WorkViews_poster_MncJb {
    flex: none;
    width: 120px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  }
}
.WorkViews_posterImage_1HlIM {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 2px;
}
.WorkViews_headerContent_3629v {
  font-size: 14px;
}
.WorkViews_headerContentWithPoster_1kH-o {
  padding-top: 8px;
  padding-left: 16px;
  margin-left: 144px;
}
@media (max-width: 480px) {
  .WorkViews_headerContentWithPoster_1kH-o {
    padding-top: 0;
    margin-left: 0;
  }
}
.WorkViews_title_1mf0S {
  margin: 0;
  font-size: 24px;
  color: hsl(94.43181818, 1%, 25%);
  word-break: keep-all;
  word-wrap: break-word;
}
@media (max-width: 480px) {
  .WorkViews_title_1mf0S {
    font-size: 20px;
  }
}
.WorkViews_stats_1yUI9 {
  color: hsl(94.43181818, 1%, 50%);
  font-size: 13px;
  margin: 10px 0 16px;
}
.WorkViews_stat_1rHZf {
  display: block;
  line-height: 1.8;
}
.WorkViews_stat_1rHZf svg {
  width: 16px;
  text-align: center;
  margin-right: 4px;
}
.WorkViews_rankStat_3HZRG {
  margin-right: 16px;
}
.WorkViews_userStat_1eL3j {
}
.WorkViews_content_30QDM {
  clear: left;
}
.WorkViews_episodes_2-uLg {
  padding: 24px 0 16px;
}
.WorkViews_episodes_2-uLg a {
  margin-right: 16px;
  text-decoration: none;
  color: hsl(94.43181818, 1%, 50%);
  white-space: nowrap;
  display: inline-block;
}
.WorkViews_episodes_2-uLg a.recent,
.WorkViews_episodes_2-uLg a.has-post {
  font-weight: bold;
}
.WorkViews_episodes_2-uLg a:hover {
  opacity: 0.8;
}
.WorkViews_episodes_2-uLg a.active,
.WorkViews_episodes_2-uLg a.active:hover {
  color: #4bb000;
}
.WorkViews_episodeHeader_2YHq- {
  border-top: 1px solid hsl(94.43181818, 1%, 85%);
  padding-bottom: 16px;
}
.WorkViews_episodeStats_1sibh {
  color: hsl(94.43181818, 1%, 50%);
  font-size: 13px;
}
.WorkViews_episodeStats_1sibh span {
  margin-right: 8px;
}
.WorkViews_episodeStats_1sibh svg {
  width: 16px;
  text-align: center;
  margin-right: 2px;
}
@media (min-width: 481px) {
  .WorkViews_sidebar_3kzZY {
    padding-top: 24px;
  }
}
.WorkViews_metadataSection_3DtGr {
  padding-bottom: 8px;
}
.WorkViews_metadata_2KPSi {
  color: hsl(94.43181818, 1%, 25%);
  padding: 8px 0;
}
.WorkViews_metadata_2KPSi p {
  font-size: 14px;
}
.WorkViews_metadata_2KPSi p:first-child {
  margin-top: 0;
}
.WorkViews_metadataLinks_zIlnG p {
  margin: 0;
  line-height: 1.6;
  font-size: 13px;
}
.WorkViews_metadataLinks_zIlnG a {
  color: hsl(94.43181818, 1%, 25%);
  text-decoration: none;
}
.WorkViews_metadataLinks_zIlnG a:hover {
  text-decoration: underline;
}
.WorkViews_videosSection_1FCtc {
  overflow: hidden;
  padding-bottom: 24px;
}
