.WeeklyChart_item_gOjED {
  margin-bottom: 8px;
  text-decoration: none;
  clear: both;
  display: block;
  overflow: hidden;
  color: hsl(94.43181818, 1%, 25%);
}
.WeeklyChart_content_3FGLO {
  margin-left: 100px;
  padding-left: 10px;
}
.WeeklyChart_title_1k_P5 {
  font-weight: bold;
  font-size: 16px;
  padding: 8px 0;
}
.WeeklyChart_rank_J02G0 {
  font-size: 13px;
}
.WeeklyChart_up_1xgbx {
  color: red;
}
.WeeklyChart_down_2LT3o {
  color: blue;
}
.WeeklyChart_poster_2G7iv {
  float: left;
  width: 100px;
  height: 133.33333333px;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
}
.WeeklyChart_posterImage_nyFMH {
  display: block;
  width: 100%;
  height: auto;
}
