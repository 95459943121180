.TableShareDialog_contentSelector_2ehxn {
  margin-bottom: 5px;
}
.TableShareDialog_urlInput_htIDB {
  border: 1px solid hsl(94.43181818, 1%, 60%);
  border-radius: 2px;
  color: hsl(94.43181818, 1%, 25%);
  background: #fff;
  font-size: inherit;
  box-sizing: border-box;
  line-height: 28px;
  padding: 0 5px;
  width: 100%;
}
.TableShareDialog_urlInput_htIDB:focus {
  outline: none;
}
.TableShareDialog_shareButtonContainer_1PcMC {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
.TableShareDialog_baseButton_YwIc3 {
  display: block;
  border-radius: 5px;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}
.TableShareDialog_baseButton_YwIc3 svg {
  margin-right: 5px;
}
.TableShareDialog_copyButton_2P-NK {
  color: hsl(94.43181818, 1%, 60%);
}
.TableShareDialog_tweetButton_5pfwL {
  color: #1DA1F2;
}
