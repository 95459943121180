.Index-module_sidebar_2EadL,
.Index-module_timeline_18o5i {
  padding-bottom: 16px;
}
.Index-module_sectionTitle_1HLMg {
  line-height: 1;
  font-size: 1.2em;
  font-weight: normal;
  margin-bottom: 0;
  padding-bottom: 16px;
}
