.LoadMore_loadMore_Cbf-G {
  cursor: pointer;
  font-size: 0.9em;
  font-weight: bold;
  padding: 8px;
  text-align: center;
  background: #4bb000;
  color: #fff;
  border-radius: 3px;
  margin: 0 auto;
  width: 120px;
}
.LoadMore_loadMore_Cbf-G:hover {
  opacity: 0.8;
}
