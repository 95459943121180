.WorkStatusButton_baseButton_n9SUh {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}
.WorkStatusButton_button_23UIN {
  border: 1px solid #4bb000;
  background: #fff;
}
.WorkStatusButton_interestedButton_3cnl1 {
  color: #e67700;
  border-color: #e67700;
}
.WorkStatusButton_addButton_1sU6- {
  color: #4bb000;
}
.WorkStatusButton_addButton_1sU6- svg {
  margin-right: 10px;
}
.WorkStatusButton_editButton_2-Yod {
  border: 1px solid #4bb000;
  background-color: #4bb000;
  color: #fff;
}
.WorkStatusButton_editButton_2-Yod svg {
  margin-right: 10px;
  color: #fff;
}
.WorkStatusButton_editButtonSubtext_o3nnl {
  margin-left: 4px;
  font-size: 13px;
  font-weight: normal;
}
.WorkStatusButton_buttonSeparator_1x8kC {
  display: inline-block;
  width: 8px;
}
