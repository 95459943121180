.Post-module_post_ijXoh {
  font-size: 14px;
  line-height: 1.8;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid hsl(94.43181818, 1%, 85%);
  background-color: #fff;
  border-radius: 2px;
}
.Post-module_user_1rVak {
  font-weight: bold;
  color: #4bb000;
  text-decoration: none;
}
.Post-module_user_1rVak:hover {
  text-decoration: underline;
}
.Post-module_metaSeparator_3ScUO {
  margin: 0 6px;
  color: #999;
}
.Post-module_work_2TjU4 {
  color: #000;
  text-decoration: none;
  margin-right: 8px;
  font-weight: bold;
}
.Post-module_work_2TjU4:hover {
  text-decoration: underline;
}
.Post-module_time_3WCFl {
  margin-left: 16px;
  font-size: 12px;
  color: #666;
  text-decoration: none;
}
.Post-module_time_3WCFl:hover {
  text-decoration: underline;
}
.Post-module_comment_3ZAWE {
  padding-top: 4px;
  line-height: 1.6;
}
.Post-module_episodeWithoutTitle_kXh_D {
  font-weight: bold;
  color: #666;
}
.Post-module_highlightedPost_JqBXA {
  padding: 16px;
  background: #f4f4f4;
  border-radius: 5px;
}
.Post-module_highlightedPost_JqBXA .Post-module_comment_3ZAWE {
  font-size: 20px;
}
