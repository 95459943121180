.StatusInput_plus_3qklp {
  display: inline-block;
  vertical-align: middle;
  background: url(data:image/gif;base64,R0lGODlhEAAQAJECAP///6uopv///wAAACH5BAEAAAIALAAAAAAQABAAAAIbVI6Zpu0PIwRAImoPjrT353nSZpFjlaWqxbAFADs=) no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.StatusInput_plus_3qklp:active {
  position: relative;
  left: 1px;
  top: 1px;
}
