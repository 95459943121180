.VideoSearch_item_3Yh9w {
  clear: both;
  display: block;
  text-decoration: none;
  color: hsl(94.43181818, 1%, 25%);
  margin-bottom: 8px;
  overflow: hidden;
}
.VideoSearch_thumbnail_3pz7e {
  float: left;
  width: 100px;
}
.VideoSearch_thumbnail_3pz7e img {
  width: 100%;
  border-radius: 2px;
}
.VideoSearch_itemContent_1rFsE {
  margin-left: 100px;
  padding-left: 10px;
}
.VideoSearch_title_246Ga {
  font-size: 13px;
}
.VideoSearch_date_2zmNt {
  font-size: 11px;
  padding-top: 5px;
  color: hsl(94.43181818, 1%, 50%);
}
