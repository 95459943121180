html {
  line-height: 1.2;
}
body,
input,
textarea,
select,
optgroup,
option,
button {
  margin: 0;
  font-family: "Helvetica Neue", "HiraKakuProN-W3", "Hiragino Sans GB W3", "Nanum Gothic", "Apple SD Gothic Neo", "Malgun Gothic", sans-serif;
}
.grid-row {
  clear: both;
}
@media (max-width: 480px) {
  .grid-column-sm-1 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .grid-column-sm-2 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .grid-column-sm-3 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 25%;
  }
  .grid-column-sm-4 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .grid-column-sm-5 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .grid-column-sm-6 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 50%;
  }
  .grid-column-sm-7 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .grid-column-sm-8 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .grid-column-sm-9 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 75%;
  }
  .grid-column-sm-10 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .grid-column-sm-11 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .grid-column-sm-12 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 100%;
  }
  .hide-mobile {
    display: none;
  }
}
@media (min-width: 481px) {
  .grid-column-1 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .grid-column-2 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .grid-column-3 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 25%;
  }
  .grid-column-4 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .grid-column-5 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .grid-column-6 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 50%;
  }
  .grid-column-7 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .grid-column-8 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .grid-column-9 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 75%;
  }
  .grid-column-10 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .grid-column-11 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .grid-column-12 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 100%;
  }
  .show-mobile {
    display: none !important;
  }
}
@media (min-width: 481px) and (max-width: 999px) {
  .grid-column-md-1 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .grid-column-md-2 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .grid-column-md-3 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 25%;
  }
  .grid-column-md-4 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .grid-column-md-5 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .grid-column-md-6 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 50%;
  }
  .grid-column-md-7 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .grid-column-md-8 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .grid-column-md-9 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 75%;
  }
  .grid-column-md-10 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .grid-column-md-11 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .grid-column-md-12 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 100%;
  }
  .grid-row {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (min-width: 980px) {
  .grid-row {
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
  }
}
