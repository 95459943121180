.PostComment_spoilerAlert_225ud {
  color: #999;
  font-size: 13px;
}
.PostComment_spoilerAlert_225ud svg {
  margin-right: 4px;
}
.PostComment_revealLink_1ij50 {
  color: #4bb000;
  cursor: pointer;
  margin-left: 6px;
  font-style: normal;
}
.PostComment_revealLink_1ij50:hover {
  text-decoration: underline;
}
