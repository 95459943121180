.Switch_container_K42k- {
  line-height: 22px;
  display: inline-block;
  white-space: nowrap;
}
.Switch_flexContainer_2g3ms {
  display: flex;
  line-height: 28px;
}
.Switch_item_30jXm {
  border: thin solid #4bb000;
  border-width: 1px 0;
  padding: 0 5px;
  color: hsl(94.43181818, 1%, 25%);
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  flex: 1;
  border-left: 1px solid #4bb000;
}
.Switch_item_30jXm:first-child {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.Switch_item_30jXm:last-child {
  border-right-width: 1px;
  border-radius: 0 2px 2px 0;
}
.Switch_item_30jXm:hover {
  color: #4bb000;
}
.Switch_itemMinimal_hLWyr {
  padding: 0 5px;
  color: hsl(94.43181818, 1%, 25%);
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  flex: 1;
}
.Switch_itemMinimal_hLWyr:hover {
  color: #4bb000;
}
.Switch_activeItem_2ptmH {
  background-color: #4bb000;
  color: #fff !important;
}
.Switch_activeItemMinimal_1ern3 {
  font-weight: bold;
  color: #4bb000;
}
