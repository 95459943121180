.Modal_fullSize_uOc9c {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.Modal_container_27Sm9 {
  position: fixed;
  z-index: 2001;
  overflow-y: auto;
  pointer-events: none;
}
.Modal_backdrop_28sV_ {
  position: fixed;
  background: #000;
  opacity: 0.4;
  z-index: 2001;
}
.Modal_dialog_xh1-t {
  pointer-events: auto;
  position: relative;
  margin: 0 auto;
  width: 320px;
  max-width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 16px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  font-size: 14px;
}
@media (max-width: 480px) {
  .Modal_dialog_xh1-t {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 481px) {
  .Modal_dialog_xh1-t {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.Modal_mobileBottomSheet_1bEZF {
}
@media (max-width: 480px) {
  .Modal_mobileBottomSheet_1bEZF {
    border-radius: 0px;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
}
.Modal_header_KatgA {
  margin-bottom: 20px;
}
.Modal_closeButton_1rilW {
  float: right;
  border: none;
  background: transparent;
  cursor: pointer;
  height: 20px;
  padding: 0;
  color: #666;
}
.Modal_title_zNm7Q {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
}
.Modal_baseButton_1dnul {
  display: block;
  border: none;
  border-radius: 5px;
  height: 36px;
  padding: 0 16px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.Modal_baseButton_1dnul:disabled {
  background: #999;
}
.Modal_button_l5QrK {
  float: right;
  margin-left: 10px;
}
.Modal_dangerConfirmButton_2z4F4 {
  background: #c9302c;
}
.Modal_confirmButton_3c_VZ {
  background: #4bb000;
}
.Modal_fullConfirmButton_-XQ5k {
  background: #4bb000;
  width: 100%;
}
.Modal_cancelButton_3f-aO {
  color: hsl(94.43181818, 1%, 25%);
  background: transparent;
}
