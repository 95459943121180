.ManageCategory_title_2ZMbQ {
  line-height: 1;
  font-size: 1.2em;
  font-weight: normal;
  margin-bottom: 0;
  padding-bottom: 16px;
}
.ManageCategory_sort_3_6qn {
  margin-bottom: 30px;
}
.ManageCategory_button_aFtUo {
  background: #4bb000;
  color: #fff;
  border: 1px solid #357d00;
  font-size: 14px;
  cursor: pointer;
}
.ManageCategory_item_1CsCe {
  border: 1px solid hsl(94.43181818, 1%, 85%);
  border-radius: 3px;
  background-color: hsl(94.43181818, 1%, 97%);
  margin-bottom: 10px;
  padding: 5px 8px;
}
.ManageCategory_itemName_27Ufx {
  font-weight: bold;
}
.ManageCategory_itemAction_3X2Dc {
  font-size: 0.9em;
  color: #06c;
  margin-left: 10px;
  cursor: pointer;
}
.ManageCategory_sortingItem_34Bfw {
  cursor: move;
}
.ManageCategory_sortingItem_34Bfw span {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
