.Dropdown_dropdown_3m2u6 a {
  line-height: 1;
  padding: 10px;
  display: block;
  color: hsl(94.43181818, 1%, 25%);
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Dropdown_dropdown_3m2u6 a:hover,
.Dropdown_dropdown_3m2u6 a:focus {
  background-color: hsl(94.43181818, 1%, 97%);
}
