.ManageRating-module_title_3HYyS {
  line-height: 1;
  font-size: 1.2em;
  font-weight: normal;
  margin-bottom: 0;
}
.ManageRating-module_help_18GXE {
  font-size: 14px;
  color: hsl(94.43181818, 1%, 50%);
  padding-top: 10px;
  padding-bottom: 20px;
}
.ManageRating-module_sidebar_14Khu {
  position: sticky;
  top: 48px;
  z-index: 99;
  background-color: #fff;
  margin-top: -16px;
  padding-top: 16px;
  margin-bottom: 20px;
}
.ManageRating-module_stuckSidebar_1d489 {
}
@media (max-width: 480px) {
  .ManageRating-module_stuckSidebar_1d489 {
    box-shadow: 0 2px 3px 0 hsla(94.43181818, 1%, 78%, 0.5);
  }
}
.ManageRating-module_progress_25HQW {
  text-align: center;
  font-size: 1.2em;
  color: hsl(94.43181818, 1%, 10%);
}
.ManageRating-module_statsTable_3hKoy {
  width: 100%;
  background-color: #fff;
  margin-top: 16px;
  margin-bottom: 16px;
  border-collapse: collapse;
}
.ManageRating-module_ratingCell_2_g-i {
  width: 3em;
  height: 24px;
  font-size: 14px;
  color: hsl(94.43181818, 1%, 25%);
  text-align: left;
}
.ManageRating-module_bar_2oiYn {
  width: 100%;
  background-color: hsl(94.43181818, 1%, 85%);
}
.ManageRating-module_barInner_bImgY {
  height: 5px;
  background-color: #4bb000;
  transition: 0.25s width;
}
.ManageRating-module_countCell_8CCwy {
  width: 0;
  font-size: 13px;
  color: hsl(94.43181818, 1%, 25%);
  padding-left: 5px;
}
.ManageRating-module_rateEntry_13aur {
  flex: 1;
  box-shadow: 0 1px 4px 0 hsla(94.43181818, 1%, 78%, 0.8);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 13px;
  text-align: center;
}
.ManageRating-module_rateEntryFiller_1sZAL {
  flex: 1;
  padding: 10px;
}
.ManageRating-module_rateEntryTitle_1cykH {
  color: hsl(94.43181818, 1%, 25%);
  letter-spacing: -0.02em;
  line-height: 1.4;
  font-size: 16px;
  font-weight: bold;
  word-break: keep-all;
  text-decoration: none;
}
.ManageRating-module_rateEntryTitle_1cykH:hover {
  color: #4bb000;
}
.ManageRating-module_rateEntryRating_2eXwL {
  padding-top: 5px;
}
.ManageRating-module_loadMore_6fXdd {
  padding-bottom: 20px;
}
@media screen and (min-width: 769px) {
  .ManageRating-module_rateEntryRow_K5srI {
    display: flex;
  }
  .ManageRating-module_rateEntry_13aur:first-child {
    margin-right: 11px;
  }
}
.ManageRating-module_empty_2Xksi {
  text-align: center;
}
.ManageRating-module_empty_2Xksi h1 {
  font-size: 1.5em;
  font-weight: normal;
}
.ManageRating-module_empty_2Xksi p {
  color: hsl(94.43181818, 1%, 25%);
}
