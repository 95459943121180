.AddRecordDialog_form_1NkcJ {
  clear: both;
  margin: 0;
}
.AddRecordDialog_field_1ccfA {
  padding-bottom: 16px;
  font-size: 14px;
}
.AddRecordDialog_field_1ccfA > label {
  display: block;
  color: hsl(94.43181818, 1%, 25%);
  font-size: 13px;
  padding-bottom: 8px;
}
.AddRecordDialog_field_1ccfA input,
.AddRecordDialog_field_1ccfA textarea,
.AddRecordDialog_field_1ccfA select {
  border: 1px solid hsl(94.43181818, 1%, 60%);
  border-radius: 2px;
  color: hsl(94.43181818, 1%, 25%);
  background: #fff;
  font-size: inherit;
  box-sizing: border-box;
}
.AddRecordDialog_field_1ccfA input {
  line-height: 28px;
  padding: 0 5px;
}
.AddRecordDialog_field_1ccfA textarea {
  width: 100%;
  padding: 5px;
}
.AddRecordDialog_field_1ccfA select {
  width: 100%;
  height: 28px;
  padding: 0 5px;
}
.AddRecordDialog_field_1ccfA .twitter-typeahead {
  width: 100%;
}
.AddRecordDialog_field_1ccfA .tt-hint,
.AddRecordDialog_field_1ccfA .tt-input {
  width: 100%;
}
.AddRecordDialog_shareOptions_1uACe {
  float: left;
  padding-top: 10px;
  color: hsl(94.43181818, 1%, 50%);
  font-size: 13px;
}
.AddRecordDialog_shareOptions_1uACe label {
  cursor: help;
}
.AddRecordDialog_shareOptions_1uACe svg {
  margin-left: 4px;
}
