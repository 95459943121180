.Library_navButtonGroup_19uAt {
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  .Library_navButtonGroup_19uAt {
    display: flex;
  }
}
.Library_navButton_2LfjH {
  border-radius: 2px;
  display: block;
  text-decoration: none;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  flex: 1;
}
.Library_addRecordButton_2dNOV {
  border: 1px solid #4bb000;
  background-color: #4bb000;
  color: #fff;
  margin-bottom: 8px;
}
@media (max-width: 480px) {
  .Library_addRecordButton_2dNOV {
    margin-right: 10px;
    margin-bottom: 0;
  }
}
.Library_manageRatingButton_1Hbos {
  border: 1px solid #4bb000;
  color: #4bb000;
}
.Library_sort_2gRhT {
  padding-bottom: 20px;
  font-size: 14px;
  word-break: break-all;
}
.Library_toc_1r7J1 {
  font-size: 14px;
  padding-top: 8px;
  line-height: 2;
}
.Library_toc_1r7J1 a {
  color: #4bb000;
  margin-right: 8px;
  text-decoration: none;
}
.Library_mobileFilterToggle_3Z4y3 {
  float: right;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
@media (min-width: 481px) {
  .Library_mobileFilterToggle_3Z4y3 {
    display: none;
  }
}
.Library_group_1cTPm {
  border-top: 1px solid hsl(94.43181818, 1%, 85%);
  font-size: 15px;
  padding-top: 10px;
}
.Library_group_1cTPm#group1 {
  border-top: 0;
  padding-top: 0;
}
.Library_groupTitle_3eblT {
  margin: 0;
  line-height: 2;
  padding-bottom: 16px;
  font-size: 15px;
  font-weight: normal;
  color: hsl(94.43181818, 1%, 25%);
}
.Library_groupItems_3Q2e8 {
  margin-left: -8px;
  padding-bottom: 8px;
}
.Library_groupItem_1j16D {
  padding-bottom: 8px;
}
.Library_groupItem_1j16D a {
  display: inline-block;
  padding: 0 8px;
  line-height: 2;
  text-decoration: none;
  color: hsl(94.43181818, 1%, 50%);
  cursor: pointer;
}
.Library_groupItem_1j16D a:hover,
.Library_groupItem_1j16D a.active {
  border-radius: 4px;
  background: hsl(94.43181818, 1%, 50%);
  color: #fff !important;
}
.Library_groupItem_1j16D .item-status {
  margin-left: 8px;
  font-weight: bold;
  white-space: nowrap;
}
.Library_groupItem_1j16D .item-updated {
  margin-left: 8px;
  font-size: 12px;
  color: #ff8c00;
}
.Library_groupItem_1j16D.item-watching a {
  color: #000;
  font-weight: bold;
}
.Library_groupItem_1j16D.item-watching a:hover,
.Library_groupItem_1j16D.item-watching a.active {
  background-color: #4bb000;
}
.Library_groupItem_1j16D.item-watching a:hover .item-status,
.Library_groupItem_1j16D.item-watching a.active .item-status {
  color: #fff;
}
.Library_groupItem_1j16D.item-watching .item-status {
  color: #4bb000;
}
.Library_notice_1qNB2 {
  border: 1px solid hsl(94.43181818, 1%, 85%);
  background-color: hsl(94.43181818, 1%, 97%);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
}
.Library_notice_1qNB2 h3 {
  font-size: 1.2em;
  display: block;
  margin: 0;
  padding-bottom: 5px;
}
.Library_notice_1qNB2 p {
  margin: 0;
  color: hsl(94.43181818, 1%, 25%);
}
.Library_notice_1qNB2 p a {
  color: hsl(94.43181818, 1%, 50%);
}
.Library_empty_2kgV3 {
  text-align: center;
}
.Library_empty_2kgV3 h1 {
  font-size: 1.5em;
  font-weight: normal;
}
.Library_empty_2kgV3 p {
  color: hsl(94.43181818, 1%, 25%);
}
.Library_empty_2kgV3 p a {
  color: #4bb000;
}
