.mixin-btn-submit {
  background: #4bb000;
  color: #fff;
  border: 1px solid #357d00;
  font-size: 14px;
  cursor: pointer;
}
.PostComposer_postComposer_3Jb__,
.PostComposer_postComposer_3Jb__ input,
.PostComposer_postComposer_3Jb__ select {
  font-size: 16px;
}
.PostComposer_postComposer_3Jb__ textarea {
  width: 30em;
  max-width: 100%;
  box-sizing: border-box;
}
.PostComposer_postComposer_3Jb__ button {
  background: #4bb000;
  color: #fff;
  border: 1px solid #357d00;
  font-size: 14px;
  cursor: pointer;
  padding: 3px 5px;
  line-height: 1;
}
.PostComposer_progress_1KJOz {
  padding-bottom: 0.5em;
}
.PostComposer_currentProgress_2nr_d {
  color: #666;
}
.PostComposer_plusOne_-Ab8V {
  margin-left: 0.25em;
  text-decoration: none;
  vertical-align: middle;
  position: relative;
}
.PostComposer_plusOne_-Ab8V:active {
  top: 1px;
  left: 1px;
}
.PostComposer_actions_349La {
  padding-top: 0.5em;
}
.PostComposer_actions_349La label {
  margin-right: 1em;
  font-size: 14px;
}
.PostComposer_disabledLabel_1UdE3 {
  color: hsl(94.43181818, 1%, 50%);
  cursor: help;
}
.PostComposer_disabledLabel_1UdE3 svg {
  margin-left: 4px;
}
