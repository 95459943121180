.LoginForm_loginErrors_23IfB {
  background-color: #ffa;
  border: 1px solid #deb200;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 16px;
  font-size: 13px;
}
.LoginForm_loginRowGroup_3OArd {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.LoginForm_loginRow_2rJ_a {
  position: relative;
  height: 70px;
}
.LoginForm_loginRow_2rJ_a:first-child {
  border-bottom: 1px solid #ddd;
}
.LoginForm_loginRow_2rJ_a label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 10px;
  color: #666;
  font-size: 12px;
  font-weight: bold;
  pointer-events: none;
}
.LoginForm_loginRow_2rJ_a input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 35px 10px 10px;
  line-height: 25px;
  font-size: 18px;
  width: 100%;
  border: none;
  background: #fff;
  box-sizing: border-box;
}
.LoginForm_loginRow_2rJ_a input:focus {
  background: #f8f8f8;
}
.LoginForm_loginCheckRow_t93xA {
  height: 36px;
  line-height: 36px;
  font-size: 14px;
}
.LoginForm_loginCheckRow_t93xA input {
  padding: 0;
  margin-right: 10px;
  vertical-align: middle;
}
