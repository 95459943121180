.GlobalHeader_container_2Sedh {
  padding-top: 48px;
}
.GlobalHeader_notice_1JxU9 {
  padding: 8px 0;
  font-size: 13px;
  color: hsl(94.43181818, 1%, 25%);
  border-bottom: 1px solid hsl(94.43181818, 1%, 85%);
  text-align: center;
}
.GlobalHeader_hero_1Zs4e {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  background: #e4f3d9;
  border-bottom: 1px solid hsl(94.43181818, 1%, 85%);
}
@media (max-width: 480px) {
  .GlobalHeader_hero_1Zs4e {
    font-size: 14px;
  }
}
.GlobalHeader_slogan_2RBqW {
  text-align: center;
  font-size: 1.8em;
  color: hsl(94.43181818, 1%, 25%);
}
.GlobalHeader_sloganBrand_Xv3L5 {
  color: #4bb000;
  white-space: nowrap;
}
.GlobalHeader_subSlogan_SolYF {
  text-align: center;
  padding-top: 1em;
  color: hsl(94.43181818, 1%, 50%);
  font-size: 1em;
  line-height: 1.5;
}
.GlobalHeader_heroActions_3eNXu {
  padding-top: 1em;
  text-align: center;
}
.GlobalHeader_largeButton_3et9H {
  display: inline-block;
  border: 1px solid;
  border-radius: 4px;
  font-size: 1.1em;
  box-sizing: border-box;
  height: 2.5em;
  line-height: 2.5em;
  padding: 0 20px;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
}
.GlobalHeader_heroLoginButton_31Jt2 {
  border-color: hsl(94.43181818, 1%, 85%);
  background: #fff;
  color: hsl(94.43181818, 1%, 25%);
}
.GlobalHeader_heroSignUpButton_jcyMT {
  border-color: #4bb000;
  background: #4bb000;
  color: #e4f3d9;
  margin-left: 16px;
}
.GlobalHeader_noticeSeparator_wjmY_ {
  color: hsl(94.43181818, 1%, 25%);
  margin: 0 0.5em;
}
.GlobalHeader_noticeLink_3QP44 {
  color: hsl(94.43181818, 1%, 25%);
}
.GlobalHeader_header_LWYzw {
  line-height: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #4bb000;
  box-shadow: 0 1px 3px 0 hsl(94.43181818, 1%, 78%);
}
.GlobalHeader_headerInner_1KRwk {
  display: flex;
  height: 48px;
}
.GlobalHeader_logo_K0dVr {
  align-self: center;
}
.GlobalHeader_logo_K0dVr a {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}
.GlobalHeader_accountMenu_1TEjF {
  position: relative;
  line-height: 48px;
}
.GlobalHeader_button_2OyVH {
  display: inline-block;
  border: 1px solid;
  border-radius: 2px;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  padding: 0 10px;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  margin-left: 8px;
}
.GlobalHeader_userButton_1-I7u {
  font-size: 13px;
  color: #e4f3d9;
  text-decoration: none;
  padding: 8px 0;
  margin-left: 16px;
}
.GlobalHeader_userButton_1-I7u .fa-user {
  margin-right: 5px;
}
.GlobalHeader_userButton_1-I7u .fa-caret-down {
  margin-left: 5px;
}
.GlobalHeader_loginButton_3PZ9a {
  border-color: #e4f3d9;
  color: #e4f3d9;
}
.GlobalHeader_signUpButton_3ZSUH {
  border-color: #fff;
  background: #fff;
  color: #4bb000;
}
.GlobalHeader_search_1JuaX {
  align-self: center;
  line-height: 1;
  width: 240px;
  font-size: 14px;
}
.GlobalHeader_search_1JuaX .twitter-typeahead {
  width: 100%;
}
.GlobalHeader_search_1JuaX .tt-hint,
.GlobalHeader_search_1JuaX .tt-input {
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 0 8px;
  border: 0;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
}
.GlobalHeader_search_1JuaX .tt-hint:focus,
.GlobalHeader_search_1JuaX .tt-input:focus {
  background-color: #fff;
}
@media (max-width: 768px) {
  .GlobalHeader_search_1JuaX {
    display: none;
  }
}
.tt-dropdown-menu {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0 0 8px 0;
  display: block;
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
}
.tt-suggestion {
  display: block;
  padding: 5px !important;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.4;
}
.tt-suggestion .count {
  font-size: 11px;
  color: hsl(94.43181818, 1%, 50%);
  white-space: nowrap;
  display: inline-block;
}
.tt-suggestion.tt-cursor {
  background-color: hsl(94.43181818, 1%, 97%);
}
.GlobalHeader_userMenu_3Yu0A {
  right: 0;
  top: 48px;
  width: 200px;
}
.GlobalHeader_userMenuSeparator_3IXs- {
  border-top: 1px solid hsl(94.43181818, 1%, 85%);
  font-size: 13px;
  font-weight: bold;
  background-color: hsl(94.43181818, 1%, 97%);
  color: hsl(94.43181818, 1%, 25%);
  line-height: 1.8;
  padding: 0 10px;
}
.GlobalHeader_quickRecordStatus_3_FAP {
  color: #4bb000;
  margin-left: 5px;
  font-weight: bold;
}
.GlobalHeader_quickRecordViewAll_2UB8J {
  color: hsl(94.43181818, 1%, 50%) !important;
  line-height: 1.8 !important;
  padding: 0 !important;
  text-align: center;
  border-top: 1px dashed hsl(94.43181818, 1%, 85%);
}
.GlobalHeader_menuToggle_2tlLv {
  float: left;
  line-height: 48px;
  margin-right: 16px;
  cursor: pointer;
  position: relative;
}
.GlobalHeader_menuToggleNormal_1-yvO {
  color: #e4f3d9;
}
.GlobalHeader_menuToggleActive_3ardf {
  color: #fff;
}
.GlobalHeader_globalMenu_1yuyk {
  display: flex;
  flex: 1;
}
@media (min-width: 481px) {
  .GlobalHeader_globalMenu_1yuyk {
    flex: auto;
    padding-left: 16px;
    font-size: 22px;
  }
}
.GlobalHeader_globalMenuItem_1F0tB {
  color: #e4f3d9;
  text-decoration: none;
  display: flex;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  box-sizing: border-box;
  height: 48px;
  padding: 0 16px;
  position: relative;
  align-items: center;
}
.GlobalHeader_globalMenuItem_1F0tB:hover {
  color: #fff;
}
@media (max-width: 480px) {
  .GlobalHeader_globalMenuItem_1F0tB {
    justify-content: center;
    flex: 1;
    text-align: center;
    font-size: 22px;
  }
}
.GlobalHeader_globalMenuItemText_2Haow {
  padding-left: 8px;
  font-size: 14px;
}
@media (max-width: 768px) {
  .GlobalHeader_globalMenuItemText_2Haow {
    display: none;
  }
}
.GlobalHeader_activeGlobalMenuItem_10OdX {
  color: #fff;
  border-bottom: 3px solid #fff;
  font-weight: bold;
}
.GlobalHeader_globalMenuItemPopover_3o9OA {
  position: fixed;
  top: 56px;
  z-index: 100;
  color: rgba(255, 255, 255, 0.95);
  background: #555;
  box-shadow: rgba(0, 0, 0, 0.24) 0 0 8px 0;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.2;
  padding: 8px;
  pointer-events: none;
}
.GlobalHeader_globalMenuItemPopover_3o9OA:after {
  bottom: 100%;
  left: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: #555;
  border-width: 5px;
}
@media (max-width: 480px) {
  .GlobalHeader_globalMenuItemPopover_3o9OA:after {
    left: 50%;
    margin-left: -5px;
  }
}
@media (min-width: 481px) {
  .GlobalHeader_globalMenuItemPopover_3o9OA {
    margin-left: -8px;
  }
}
