.UserHistory_group_NaLdR {
  padding-top: 16px;
}
.UserHistory_group_NaLdR:first-child {
  padding-top: 0;
}
.UserHistory_groupTitle_1Zp7n {
  line-height: 1;
  font-size: 1.2em;
  font-weight: normal;
  margin-bottom: 0;
  padding-bottom: 16px;
}
