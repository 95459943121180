.UserLayout_header_SyB0Q {
  overflow: hidden;
  border-bottom: 1px solid hsl(94.43181818, 1%, 85%);
}
@media (max-width: 480px) {
  .UserLayout_profile_3gRq5 {
    padding: 16px 0;
  }
}
@media (min-width: 481px) {
  .UserLayout_profile_3gRq5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 64px;
  }
}
.UserLayout_title_1oype {
  margin: 0;
  font-size: 20px;
}
.UserLayout_title_1oype a {
  color: hsl(94.43181818, 1%, 10%);
  text-decoration: none;
}
.UserLayout_title_1oype a:hover {
  color: #4bb000;
}
.UserLayout_dateJoined_2_tNs {
  line-height: 2;
  color: hsl(94.43181818, 1%, 50%);
  font-size: 12px;
}
.UserLayout_nav_24dm1 {
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  .UserLayout_nav_24dm1 {
    padding-bottom: 16px;
  }
}
@media (min-width: 481px) {
  .UserLayout_nav_24dm1 {
    height: 64px;
  }
}
.UserLayout_navItem_3iWlX {
  text-decoration: none;
  color: hsl(94.43181818, 1%, 25%);
  margin-right: 20px;
}
@media (min-width: 481px) {
  .UserLayout_navItem_3iWlX {
    font-size: 12px;
    text-align: center;
  }
}
.UserLayout_navItem_3iWlX:hover {
  color: hsl(94.43181818, 1%, 50%);
}
.UserLayout_navItemCount_2bhWj {
  color: #4bb000;
  font-weight: bold;
}
@media (min-width: 481px) {
  .UserLayout_navItemCount_2bhWj {
    display: block;
    font-size: 20px;
  }
}
.UserLayout_settingsNavItem_2W9xn {
  text-decoration: none;
  color: #4bb000;
  font-size: 14px;
}
.UserLayout_settingsNavItem_2W9xn svg {
  margin-right: 8px;
}
@media (min-width: 481px) {
  .UserLayout_settingsNavItem_2W9xn {
    border: 1px solid #4bb000;
    border-radius: 2px;
    padding: 8px 16px;
  }
}
.UserLayout_content_2sdug {
  padding: 16px 0;
}
