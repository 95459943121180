.Signup-module_container_39xum {
  padding: 20px 10px;
  margin: 0 auto;
  width: 320px;
  max-width: 100%;
  box-sizing: border-box;
}
.Signup-module_header_o01Zn {
  margin-bottom: 30px;
}
.Signup-module_title_L6rso {
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  margin: 0;
}
.Signup-module_errors_1wYfT {
  background-color: #ffa;
  border: 1px solid #deb200;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 16px;
  font-size: 13px;
}
.Signup-module_row_svHJW {
  margin-bottom: 25px;
}
.Signup-module_row_svHJW label {
  display: block;
  color: hsl(94.43181818, 1%, 10%);
  padding-bottom: 10px;
  font-weight: bold;
  pointer-events: none;
}
.Signup-module_row_svHJW input {
  padding: 10px;
  border: 1px solid hsl(94.43181818, 1%, 60%);
  line-height: 25px;
  font-size: 18px;
  width: 100%;
  border-radius: 5px;
  background: #fff;
  box-sizing: border-box;
}
.Signup-module_row_svHJW input:focus {
  background: hsl(94.43181818, 1%, 97%);
  outline: 0;
}
.Signup-module_hint_2Xe2- {
  padding-top: 10px;
  color: hsl(94.43181818, 1%, 50%);
  font-size: 14px;
}
.Signup-module_signupButton_3e1zB {
  display: block;
  background: #4bb000;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  height: 45px;
  padding: 0;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}
