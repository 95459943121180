.RecordDetail-module_header_2sA2- {
  margin-bottom: 30px;
}
@media (min-width: 481px) {
  .RecordDetail-module_header_2sA2- {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid hsl(94.43181818, 1%, 85%);
  }
}
@media (min-width: 787px) {
  .RecordDetail-module_header_2sA2- {
    padding-bottom: 16px;
    display: flex;
  }
}
.RecordDetail-module_title_1bjpr {
  flex: 1;
  margin: 0;
  font-size: 20px;
  word-break: keep-all;
}
.RecordDetail-module_title_1bjpr a {
  color: hsl(94.43181818, 1%, 10%);
  text-decoration: none;
}
.RecordDetail-module_title_1bjpr a:hover {
  color: hsl(94.43181818, 1%, 50%);
  border-bottom: 1px dashed hsl(94.43181818, 1%, 50%);
}
@media (max-width: 768px) {
  .RecordDetail-module_readOnlyRating_37x19 {
    margin-top: 16px;
  }
}
.RecordDetail-module_titleForm_1fcCA {
  flex: 1;
}
.RecordDetail-module_titleForm_1fcCA input {
  width: 15em;
}
@media (max-width: 768px) {
  .RecordDetail-module_toolbar_3j_4i {
    margin-top: 10px;
  }
}
.RecordDetail-module_toolbarButton_3tMcN {
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  font-size: 13px;
  color: hsl(94.43181818, 1%, 25%);
}
@media (max-width: 768px) {
  .RecordDetail-module_toolbarButton_3tMcN {
    line-height: 36px;
  }
}
.RecordDetail-module_ratingForm_5jxwh {
  margin-right: 20px;
  display: inline-flex;
}
.RecordDetail-module_editTitleButton_312Jr {
  margin-right: 20px;
}
.RecordDetail-module_deleteButton_2nxT_ {
  margin-right: 20px;
  color: #c9302c;
}
.RecordDetail-module_categoryForm_3qx0P {
  position: relative;
}
.RecordDetail-module_categoryForm_3qx0P label {
  color: hsl(94.43181818, 1%, 50%);
}
.RecordDetail-module_categoryForm_3qx0P select {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border: 0;
  cursor: pointer;
}
.RecordDetail-module_postComposerContainer_PDT0P {
  padding-bottom: 20px;
}
.RecordDetail-module_post_zzdgM {
  padding-bottom: 16px;
  line-height: 1.8;
  font-size: 14px;
}
.RecordDetail-module_post_zzdgM.RecordDetail-module_noComment_1chBl .RecordDetail-module_postMeta_2rv-a {
  display: inline;
}
.RecordDetail-module_post_zzdgM .RecordDetail-module_postProgress_GorQE {
  font-weight: bold;
  margin-right: 8px;
  display: inline;
}
.RecordDetail-module_post_zzdgM .RecordDetail-module_postComment_E_zhP {
  display: inline;
}
.RecordDetail-module_post_zzdgM .RecordDetail-module_postMeta_2rv-a {
  font-size: 12px;
}
.RecordDetail-module_post_zzdgM .RecordDetail-module_postTime_a0uZU {
  color: hsl(94.43181818, 1%, 50%);
  text-decoration: none;
}
.RecordDetail-module_post_zzdgM .RecordDetail-module_postTime_a0uZU:hover {
  text-decoration: underline;
}
.RecordDetail-module_post_zzdgM .RecordDetail-module_deletePostButton_3jcEr {
  margin-left: 8px;
  color: #c9302c;
  text-decoration: none;
  cursor: pointer;
}
.RecordDetail-module_post_zzdgM .RecordDetail-module_deletePostButton_3jcEr:hover {
  text-decoration: underline;
}
.RecordDetail-module_spoilerMark_1Tbhi {
  color: hsl(94.43181818, 1%, 50%);
  margin-right: 8px;
}
