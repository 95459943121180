@media (max-width: 480px) {
  .LibraryFilter-module_filter_2j38R {
    border: 1px solid hsl(94.43181818, 1%, 85%);
    background-color: hsl(94.43181818, 1%, 97%);
    padding: 16px;
    padding-bottom: 0;
    margin-bottom: 16px;
  }
}
.LibraryFilter-module_filterGroup_1ptYF {
  padding-bottom: 20px;
  font-size: 14px;
  word-break: break-all;
}
.LibraryFilter-module_filterGroupTitle_29Jyc {
  font-weight: bold;
  padding-bottom: 16px;
}
.LibraryFilter-module_filterGroupItem_3q0R1 {
  padding-bottom: 8px;
  font-size: 13px;
}
.LibraryFilter-module_filterGroupItem_3q0R1 a {
  color: hsl(94.43181818, 1%, 25%);
  text-decoration: none;
}
.LibraryFilter-module_filterGroupItem_3q0R1 a:hover {
  text-decoration: underline;
}
.LibraryFilter-module_filterGroupItemActive_34N_s {
  font-weight: bold;
  text-decoration: underline;
}
.LibraryFilter-module_manageCategoryButton_32rXr {
  color: #4bb000;
  text-decoration: none;
  font-size: 13px;
}
