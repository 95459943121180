.Settings_section_1oDqy {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
  border-bottom: 1px solid hsl(94.43181818, 1%, 85%);
  line-height: 1.6;
}
.Settings_sectionTitle_3sAda {
  flex: 1;
  margin: 0;
  font-size: 15px;
  color: hsl(94.43181818, 1%, 10%);
}
.Settings_action_3ESxW {
  font-size: 13px;
  color: hsl(94.43181818, 1%, 25%);
  text-align: right;
}
.Settings_action_3ESxW a {
  color: #4bb000;
  font-weight: bold;
  text-decoration: none;
}
.Settings_sectionContent_3-ppk {
  width: 100%;
  padding-top: 8px;
}
.Settings_changePasswordForm_2M7Gb {
  font-size: 13px;
  border-collapse: collapse;
}
.Settings_changePasswordForm_2M7Gb th {
  font-weight: normal;
  text-align: left;
  padding: 8px 8px 8px 0;
}
.Settings_logoutButton_blOIW {
  margin-top: 32px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  padding: 8px;
  text-align: center;
  font-size: 13px;
  border: 1px solid hsl(94.43181818, 1%, 60%);
  color: hsl(94.43181818, 1%, 25%);
  border-radius: 2px;
}
.Settings_logoutButton_blOIW:hover {
  opacity: 0.5;
}
